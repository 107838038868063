function setQueryStringParam(uri, key, value) {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

$(function () {
  $('#localeSelector').on('change', function (e) {
    const value = $(e.target).val()
    window.location.href = setQueryStringParam(window.location.href, 'locale', value)
  })
})
